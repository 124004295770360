
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";
import Row from "@/components/base/common/Row.vue";
import Contacts from "@/views/contact/Contacts.vue";
import InquiryStatus from "@/views/case/InquiryStatus.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {LoadPanel} from "@/core/composite/composite";
import Timelines from "@/views/timeline/Timelines.vue";
import DecodeId from "@/components/base/DecodeId.vue";
import CheckList from "@/views/checklist/CheckList.vue";
import ProductLink from "@/views/product/ProductLink.vue";

export default defineComponent({
  name: "Overview",
  components: {
    ProductLink,
    CheckList,
    DecodeId,

    Timelines,
    DateTimeFormat,
    InquiryStatus,
    Contacts,
    Row
  },
  setup() {
    const store = useStore();
    const caze = computed(() => store.state.CaseModule.case)

    return {
      caze,
      ...LoadPanel()
    }
  },
  watch: {
    caze(current, newVal) {
      if (newVal !== undefined) {
        const time = this.$refs.timelines as typeof Timelines
        time.paginationLoad()
      }
    }
  }
})
